import { graphql } from 'gatsby';
import * as React from 'react';

import Layout from '~/components/Layout';
import Seo from 'gatsby-plugin-wpgraphql-seo';

import { TealGlow, BlueGlow } from '../components/Glow';
import HeroMask from '../assets/glows/partnerHero.svg';

import Image from '../components/elements/Image';
import ArticleRenderer from '../components/article/ArticleRenderer';

import { StarPartner, GlobePartner } from '../components/elements/Icon';

const SinglePartner = ({ data: { wpPartner, wpPage, wp } }) => {
  const post = wpPartner;
  const article = wpPartner?.postTypeSinglePartner?.article;
  const aside = wpPartner?.postTypeSinglePartner?.aside;

  const LeftGlowClass = [
    ' absolute bottom-[-30%] md:bottom-[-45%] lg:bottom-[-60%] left-[-40%] sm:left-[-25%] md:left-[-40%] lg:left-[-34%] xl:left-[-24%] w-[400px] h-[400px] md:w-[800px] md:h-[800px] lg:w-[1200px] lg:h-[1200px] ',
  ];
  const RightGlowClass = [
    'absolute bottom-[-30%] md:bottom-[-45%] lg:bottom-[-60%] right-[-40%] sm:right-[-25%] md:right-[-40%] lg:right-[-34%] xl:right-[-24%] w-[400px] h-[400px] md:w-[800px] md:h-[800px] lg:w-[1200px] lg:h-[1200px]',
  ];

  return (
    <Layout wp={wp} darkHeader={true} hideFooter={true}>
      <Seo post={wpPartner} title={wpPartner?.title} />
      {/* Hero */}
      <section className="relative pt-28 pb-10 md:pb-12 bg-grey safari-overflow overflow-hidden">
        <div className="container grid md:grid-cols-2 gap-y-8 md:gap-y-0 md:gap-x-8 md:items-center">
          <div className="relative z-10 mx-auto md:ml-0 max-w-[25.8rem] text-center md:text-left">
            <h1 className="t-64 font-medium mb-5" dangerouslySetInnerHTML={{ __html: post?.title }} />
            <p className="t-18" dangerouslySetInnerHTML={{ __html: post?.postTypeSinglePartner?.masthead?.content }} />
          </div>
          <div>
            <div className="aspect-[1/1] w-full max-w-[29.25rem] relative z-10 mx-auto md:mr-0 flex items-center justify-center">
              <Image
                image={post?.postTypeSinglePartner?.masthead?.logo}
                className={'max-w-[150px] !h-auto !w-3/4 aspect-[19/5]'}
              />
              <img src={HeroMask} alt="icon galaxy swirl" className="inset-0 absolute w-full h-full object-contain" />
            </div>
          </div>
        </div>
        <>
          <TealGlow classes={`${LeftGlowClass} opacity-[.4]`} stopTwo={`30%`} stopThree={`70%`} />
          <BlueGlow classes={`${RightGlowClass} opacity-[.2]`} stopTwo={`30%`} stopThree={`70%`} />
        </>
      </section>
      {/* Content */}
      <section className="bg-offWhite py-12 md:py-20 lg:py-[100px]">
        <div className="container grid md:grid-cols-12 md:gap-x-8 gap-y-8 md:gap-y-0">
          <div className="md:col-span-7">
            <div className="md:max-w-[588px]">
              <ArticleRenderer
                content={article?.articleBlocks}
                prefix={'Partner_Posttypesinglepartner_Article_ArticleBlocks_'}
                smallGap={true}
              />
            </div>
          </div>
          {/* Sidebar */}
          <div className="md:col-span-5">
            <aside className="bg-white rounded-[0.625rem] p-6 md:px-8 py-[2.5rem] border border-navy/10 md:max-w-[30.375rem] mx-auto md:mr-0">
              <h5
                className="uppercase t-20 mb-6 md:mb-8 font-body text-blue tracking-[0.0875rem] font-bold"
                dangerouslySetInnerHTML={{ __html: 'The deal' }}
              />
              <h6
                className="uppercase t-16 mb-[.75rem] font-body opacity-40 tracking-[0.0875rem] font-bold"
                dangerouslySetInnerHTML={{ __html: 'Available To' }}
              />
              {/* Available too */}
              <div className="flex items-center justify-start flex-wrap">
                <div className="inline-flex items-center space-x-2 bg-[#EEF5FE] rounded-[.375rem] pt-[.875rem] pb-4 px-[.75rem] mr-[.75rem] mb-[.75rem]">
                  <span className="aspect-[1/1] w-[.875rem] flex items-center justify-center">
                    <StarPartner />
                  </span>
                  <span
                    className="t-18 font-medium text-blue"
                    dangerouslySetInnerHTML={{ __html: 'Repool Customers' }}
                  />
                </div>
                {aside?.avail !== 'customers' && (
                  <div className="inline-flex items-center bg-navy/5 space-x-2 rounded-[.375rem] pt-[.875rem] pb-4 px-[.75rem] mb-[.75rem]">
                    <span className="aspect-[1/1] w-[.875rem] flex items-center justify-center">
                      <GlobePartner />
                    </span>
                    <span className="t-18 font-medium" dangerouslySetInnerHTML={{ __html: 'Everyone' }} />
                  </div>
                )}
              </div>
              <p className="t-18 mt-6 md:mt-8" dangerouslySetInnerHTML={{ __html: aside?.description }} />
              {/* List */}
              {aside?.listItems?.length > 0 && (
                <ul className="space-y-4 md:space-y-5 mt-6 md:mt-[1.75rem]">
                  {aside?.listItems.map((listItem, l) => (
                    <li key={l} className="flex items-start">
                      <span className="aspect-[1/1] bg-blue rounded-full w-2 mt-[.65rem] mr-3" />
                      <span className="t-18" dangerouslySetInnerHTML={{ __html: listItem.listItem }} />
                    </li>
                  ))}
                </ul>
              )}
              {/* Link */}
              <div className="mt-8 md:mt-12">
                <a
                  href={aside?.link?.url}
                  className="bg-blue text-white font-medium leading-[1em] py-3 px-[25px] inline-flex rounded-[45px] border-2 border-black border-opacity-10 hover:border-blue hover:bg-white hover:text-blue transition-all duration-200 "
                >
                  <span className="t-16 font-medium" dangerouslySetInnerHTML={{ __html: aside?.link?.title }} />
                </a>
              </div>
            </aside>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default SinglePartner;

export const pageQuery = graphql`
  query Partner($id: String!) {
    wp {
      ...GeneratedWp
      header {
        globalUpdatedHeader {
          fieldGroupName
          headerUpdated {
            topLevelNavigation {
              link {
                title
                target
                url
              }
              addMenu
              subMenu {
                tabs {
                  heading
                  description
                  menuType
                  columnMenu {
                    columns {
                      heading
                      cards {
                        image {
                          ...Image
                        }
                        link {
                          title
                          url
                        }
                        description
                      }
                    }
                  }
                  rowMenu {
                    heading
                    cards {
                      image {
                        ...Image
                      }
                      link {
                        title
                        url
                      }
                      description
                    }
                  }
                  resourcesMenu {
                    listSubheading
                    listItems {
                      image {
                        ...Image
                      }
                      link {
                        title
                        url
                      }
                    }
                    cardsSubheading
                    cardItems {
                      thumbnail {
                        ...Image
                      }
                      link {
                        title
                        url
                      }
                      description
                    }
                  }
                }
              }
            }
            buttons {
              textLink {
                target
                title
                url
              }
              buttonLink {
                target
                title
                url
              }
            }
            socials {
              image {
                ...Image
              }
              link {
                target
                title
                url
              }
            }
          }
        }
      }
    }
    wpPartner(id: { eq: $id }) {
      title
      uri
      date
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          srcSet
          sourceUrl
          localFile {
            publicURL
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          srcSet
          localFile {
            publicURL
          }
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      postTypeSinglePartner {
        masthead {
          content
          logo {
            altText
            publicUrl
            sourceUrl
            localFile {
              publicURL
            }
          }
        }
        aside {
          avail
          description
          listItems {
            listItem
          }
          link {
            title
            url
          }
        }
        article {
          articleBlocks {
            ... on WpPartner_Posttypesinglepartner_Article_ArticleBlocks_Text {
              fieldGroupName
              heading
              text
            }
            ... on WpPartner_Posttypesinglepartner_Article_ArticleBlocks_Image {
              fieldGroupName
              image {
                ...Image
              }
            }
            ... on WpPartner_Posttypesinglepartner_Article_ArticleBlocks_Quote {
              fieldGroupName
              quote
              addContext
              by {
                name
                role
              }
            }
            ... on WpPartner_Posttypesinglepartner_Article_ArticleBlocks_Bullets {
              fieldGroupName
              heading
              bullets {
                text
              }
            }
          }
        }
      }
    }
  }
`;
